<template>
    <component :is="sectionComponent" padding-top="10px" padding-bottom="10px">
        <component
            :is="textComponent"
            font-family="'Arial', 'Helvetica', 'sans-serif'"
            line-height="18px"
            font-size="13px"
            color="#4A4A4A"
        >
            paysafecard classic <br />
            value: {{ value }} <br />
            serial number: {{ serial }} <br />
            PIN code: {{ code }} <br /><br />
            Trace code: {{ traceCode }} <br />
            Terminal ID: {{ terminalId }} <br />
        </component>

        <component :is="hrComponent" border-color="#D7DFE2" border-width="1px"></component>

        <component
            :is="textComponent"
            font-family="'Arial', 'Helvetica', 'sans-serif'"
            line-height="18px"
            font-size="13px"
            color="#4A4A4A"
        >
            <p>
                <strong>Pay online</strong>
            </p>
            <ul>
                <li>Slelect paysafecard when paying in an online shop.</li>
                <li>Simply enter the 16-digit PIN. Ready!</li>
                <li>
                    Warning: Never pay with paysafecard to unlock your computer or to claim an alleged competition
                    price.
                </li>
            </ul>
            <p>
                Find out more
                <a target="_blank" href="https://www.paysafecard.com/security">www.paysafecard.com/security</a><br />
            </p>
        </component>

        <component :is="hrComponent" border-color="#D7DFE2" border-width="1px"></component>

        <component
            :is="textComponent"
            font-family="'Arial', 'Helvetica', 'sans-serif'"
            line-height="18px"
            font-size="13px"
            color="#4A4A4A"
        >
            Help: <br />
            <a target="_blank" href="https://www.paysafecard.com/help">www.paysafecard.com/help</a>.<br /><br />
            paysafecard is a payment method issued and mangaged by <strong>Paysafe Prepaid Services Ltd</strong>.<br />
            See <a target="_blank" href="https://www.paysafecard.com">www.paysafecard.com</a> for the applicable general
            terms and conditions.
        </component>
    </component>
</template>

<script>
export default {
    props: ['isEmail', 'value', 'code', 'serial', 'terminalId', 'traceCode', 'date', 'time', 'transactionId'],

    computed: {
        sectionComponent() {
            return this.isEmail ? 'mj-section' : 'div';
        },

        textComponent() {
            return this.isEmail ? 'mj-text' : 'div';
        },

        hrComponent() {
            return this.isEmail ? 'mj-divider' : 'hr';
        },
    },
};
</script>
